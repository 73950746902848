import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FullScreenContainer } from '../components/FullScreenContainer';
import { LoadingFullScreen } from '../layout/LoadingFullScreen';
import { useRouter } from 'next/router';
import { LoginProviders } from '../components/LoginProviders';
import { GroupInviteDataFragment } from '../generated/graphql';
import { useAuthContext } from '../hooks/useAuthContext';

export const Login = () => {
  const { authStatus } = useAuthContext();
  const router = useRouter();
  const { t } = useTranslation(['auth', 'login']);
  const [invitationState, setInvitationState] = useState<GroupInviteDataFragment | null>(null);

  // console.log('auth state : ', authState);
  useEffect(() => {
    // console.log('auth state in effect: ', authState);
    if (authStatus === 'in') {
      // console.log('Navigate to pseudo');
      router.push('/pseudo');
    }
  }, [authStatus, router]);

  useEffect(() => {
    if (localStorage.getItem('pendingInvitation')) {
      setInvitationState(JSON.parse(localStorage.getItem('pendingInvitation') ?? '{}'));
    }
  }, []);

  if (authStatus === 'loading') {
    return <LoadingFullScreen debugLabel="login auth state loading" />;
  }
  if (!authStatus) {
    return <LoadingFullScreen debugLabel="login authState is undefined" />;
  }
  if (authStatus === 'in') {
    return <LoadingFullScreen debugLabel="login authState is in" />;
  }

  let inviteWording;
  if (invitationState?.group?.name) {
    const groupName = invitationState.group.name;
    inviteWording = (
      <Trans i18nKey="login:groupInviteWording">
        The <span className="text-blue-500 font-semibold">{{ groupName }}</span> group would like you to join them. Are
        you ready for it?
      </Trans>
    );
  }
  return (
    <FullScreenContainer variant="auth">
      <div className="flex flex-col px-4 md:px-16 lg:px-20 py-10 items-center">
        <h1 className={`${inviteWording ? 'mb-4' : 'mb-10'} font-black text-3xl`}>{t('auth:login.sign-in-heading')}</h1>
        {inviteWording && <div className="text-gray-500 text-center w-2/3 mb-4">{inviteWording}</div>}
        <LoginProviders />
      </div>
    </FullScreenContainer>
  );
};
export default Login;
