import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FacebookLogo, GoogleLogo, TwitterLogo } from '../../assets/icons';
import { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider, signInWithRedirect } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { showToast } from '../Toast/';

export const LoginProviders = () => {
  const { t } = useTranslation(['auth']);

  const login = async (
    provider: typeof GoogleAuthProvider | typeof FacebookAuthProvider | typeof TwitterAuthProvider,
  ) => {
    try {
      await signInWithRedirect(auth, new provider());
    } catch (e) {
      const errorTitle = t('auth:error.title');

      if (e.name === 'FirebaseError') {
        if (e.code === 'auth/account-exists-with-different-credential') {
          showToast({
            title: errorTitle,
            description: t('auth:error.accountExistsOtherSocial', {
              provider: e.customData._tokenResponse.verifiedProvider[0],
            }),
            type: 'error',
          });
        } else if (e.code === 'auth/cancelled-popup-request') {
        } else if (e.code === 'auth/popup-blocked') {
          showToast({
            title: errorTitle,
            description: t('auth:error.popupBlocked'),
            type: 'error',
          });
        } else if (e.code === 'auth/popup-closed-by-user') {
          showToast({
            title: errorTitle,
            description: t('auth:error.popupClosed'),
            type: 'error',
          });
        }
      } else {
        showToast({
          title: errorTitle,
          description: t('auth:error.genericBody'),
          type: 'error',
        });
        console.error(e);
      }
    }
  };

  const signInProviders = [
    {
      authProvider: GoogleAuthProvider,
      button: GoogleButton,
    },
    {
      authProvider: FacebookAuthProvider,
      button: FacebookButton,
    },
    {
      authProvider: TwitterAuthProvider,
      button: TwitterButton,
    },
  ];

  return (
    <div className="space-y-4">
      {signInProviders.map((provider) => {
        const Button = provider.button;
        return <Button key={provider.authProvider.name} onClick={() => login(provider.authProvider)} />;
      })}
    </div>
  );
};

const defaultSocialButtonClassNames =
  'rounded-lg focus:outline-none px-4 text-base font-sans font-semibold cursor-pointer leading-normal inline-flex align-middle justify-center items-center min-h-10 w-full py-4';

interface SocialLoginButtonProps {
  onClick: () => void;
}

const GoogleButton: React.FC<SocialLoginButtonProps> = ({ onClick }) => {
  const { t } = useTranslation(['auth']);

  return (
    <button
      onClick={onClick}
      className={clsx(defaultSocialButtonClassNames, 'border border-gray-300 bg-white hover:bg-gray-200')}
    >
      <GoogleLogo className="mr-2 h-5 w-5" aria-hidden="true" focusable="false" />
      {t('auth:login.continue-with-google')}
    </button>
  );
};

const FacebookButton: React.FC<SocialLoginButtonProps> = ({ onClick }) => {
  const { t } = useTranslation(['auth']);

  return (
    <button
      onClick={onClick}
      className={clsx(defaultSocialButtonClassNames, 'text-white hover:opacity-75')}
      style={{ backgroundColor: '#1A77F2' }}
    >
      <FacebookLogo className="mr-2 h-5 w-5" aria-hidden="true" focusable="false" />
      {t('auth:login.continue-with-facebook')}
    </button>
  );
};

const TwitterButton: React.FC<SocialLoginButtonProps> = ({ onClick }) => {
  const { t } = useTranslation(['auth']);

  return (
    <button
      onClick={onClick}
      className={clsx(defaultSocialButtonClassNames, 'text-white hover:opacity-75')}
      style={{ backgroundColor: '#1EA1F1' }}
    >
      <TwitterLogo className="mr-2 h-5 w-5" aria-hidden="true" focusable="false" />
      {t('auth:login.continue-with-twitter')}
    </button>
  );
};
